import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import UserNavbarComponent from "../../components/Navbar/UserNavbarComponent";
import theme from "../../theme";
import { ThemeProvider } from "@emotion/react";
import FooterComponent from "../../components/Footer/FooterComponent";
import { Box, TablePagination, Fab, useMediaQuery, Slide, Backdrop, IconButton } from '@mui/material';
import UserProductDisplayCard from "../../components/Card/UserProductDisplayCard";
import UserPropertyFilterComponent from "../../components/Drawer/UserPropertyFilterComponent";
import { GetPropertyAPI, GetPropertyByid } from "../../api/Listings/property/propertyController";
import UserProductSkeletonLoader from './../../components/Skeleton/UserProductSkeletonLoader';
import FilterListIcon from '@mui/icons-material/FilterList'; // Use MUI icon for FAB
import CloseIcon from '@mui/icons-material/Close'; // Import close icon for the filter panel
import { GetCarByid } from "../../api/Listings/car/carController";

const PropertyPage = ({ history }) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);  // 0-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [State, setState] = useState('');
  const [AddiitonFilter, setAddiitonFilter] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false); // State to control FAB menu
  const [FormFromHomePage, setFormFromHomePage] = useState({});
  const [propertyType, setpropertyType] = useState('')

  const Type = 'property'
  let allTotalCount;
  const paramLocation = useLocation(); // Use useLocation hook
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [propertyId, setpropertyId] = useState()
  useEffect(() => {
    const queryParams = new URLSearchParams(paramLocation.search);
    const State = queryParams.get('State');
    const propertyIdParam = queryParams.get('PropertyId'); // Get the Property query parameter
    const propertyType = queryParams.get('PropertyType');

    

    if (propertyType) {
      setpropertyType(propertyType)
    }
    if (State) {
      setState(State)
    }
    if (propertyIdParam) {
      setpropertyId(propertyIdParam)
    }


    const City = queryParams.get('City') || '';
    const location = queryParams.get('location') || '';
    const Type = queryParams.get('Type') || '';
    const MinPrice = queryParams.get('MinPrice') || '';
    const MaxPrice = queryParams.get('MaxPrice') || '';

    
    // Update the state with the values from query parameters
    setFormFromHomePage(prevState => ({
      ...prevState,
      ...(State === 'All' ? {} : { Status: State }), // Only add Status if it's not 'All'
      City: City,
      location: location,
      Type: Type,
      MinPrice: MinPrice,
      MaxPrice: MaxPrice
    }));


  }, [paramLocation.search]);

  useEffect(() => {
    if (!propertyId && State) {
      fetchData(page + 1, rowsPerPage, AddiitonFilter, State);
    } else {
      if (propertyId) {
        GetPropertyByid(propertyId, setData)
        setIsLoading(false);
      }

    }
  }, [page, rowsPerPage, AddiitonFilter, State, propertyId, propertyType]);

  const filterNonEmptyValues = (filter) => {
    return Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => value !== null && value !== '')
    );
  };

  const fetchData = async (pageNo, pageSize, AddiitonFilter, Status, propertyType) => {
    const addStatus = !(AddiitonFilter?.Status);

    const payload = {
      pageNo,
      pageSize,
      ...(addStatus && Status && Status !== 'All' && { Status }),
      ...filterNonEmptyValues(AddiitonFilter),
      ...filterNonEmptyValues(FormFromHomePage)
    };    
    await GetPropertyAPI(payload, setData, setTotalCount, setIsLoading);
    setIsLoading(false);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleBackdropClick = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <UserNavbarComponent history={history} setState={setState} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            height: 'auto',
            position: 'relative',
            backgroundColor: theme.homePage.backgroundColor // Ensure the sliding panel is positioned relative to this container
          }}
        >
          {/* Left part (100% on small screens, 70% on large screens) */}
          <Box
            sx={{
              flex: isSmallScreen ? 1 : 7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: 'auto',
              width: '100%',
              maxWidth: '100%',  // Prevents extra width
              margin: '0',
              padding: isSmallScreen ? 0 : 0,
            }}
          >

            {
              IsLoading ?
                <UserProductSkeletonLoader /> :
                <UserProductDisplayCard
                  Data={data}
                  Type={Type}
                  history={history}
                />
            }

            <TablePagination
              component="div"
              height={'100px'}
              count={totalCount}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{
                height: 'auto', // Let it adjust to the content height
                overflow: 'hidden', // Prevent overflow issues
              }}
            />
          </Box>

          {/* Filter Panel (always visible on larger screens, toggle on small screens) */}
          {!isSmallScreen && (
            <Box
              sx={{
                flex: 3, // 30% width
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: 'auto',
                padding: '20px'
              }}
            >
              <UserPropertyFilterComponent
                initialState={State}
                totalRecord={totalCount}
                setAddiitonFilter={setAddiitonFilter}
                setIsLoading={setIsLoading}
              />
            </Box>
          )}

          {/* Floating Action Button for Filter on small screens */}
          {isSmallScreen && (
            <Fab
              color="primary"
              aria-label="filter"
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
              onClick={toggleFilter}
            >
              <FilterListIcon />
            </Fab>
          )}

          {/* Sliding Panel for Filter on small screens */}
          <Slide direction="left" in={openFilter} mountOnEnter unmountOnExit>
            <Box
              sx={{
                flex: 'none',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '100%',
                width:  300, // Fixed width for small screens
                position: 'fixed',
                right: 0,
                top: 0,
                backgroundColor: 'white',
                boxShadow: 3,
                padding: 2,
                zIndex: 1200, // Ensure it is above other content
                overflowY: 'auto',
              }}
            >
              <IconButton
                sx={{ position: 'absolute', top: 8, right: 8 }}
                onClick={toggleFilter}
              >
                <CloseIcon />
              </IconButton>
              <UserPropertyFilterComponent
                initialState={State}
                totalRecord={totalCount}
                setAddiitonFilter={setAddiitonFilter}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Slide>

          {/* Backdrop for closing filter panel */}
          {isSmallScreen && openFilter && (
            <Backdrop
              open={openFilter}
              onClick={handleBackdropClick}
              sx={{ zIndex: 1100 }} // Ensure it is below the sliding panel
            />
          )}
        </Box>

        <FooterComponent />
      </ThemeProvider>
    </>


  );
}

export default PropertyPage;
