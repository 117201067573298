import { createTheme } from "@mui/material";
const theme = createTheme({
    palette: {      
      primary: {
        main: '#5B1144',
        contrastText: "#fff"        
      },
      secondary:{
        main:'#6750A4'
      },
      action:{
        main:'#EADDFF'
      },
      text:{
        main:'#001619'
      },
      success:{
        main:'#008080'
      },
      warning:{
        main:'#FFA700'
      },
      error:{
        main:"#C30000"
      },
      default:{
        main:"#fff"
      }
      
    },
    components:{
      dashboard:{
        titleColor:'#7A165B',
        saleCircleIcon:'#6FD195',
        rentCircleIcon:"#FFAE4C",
        saleCarCircleIcon:"#FFAE4C",
        inquiryOtherCircleIcon:"#ea615e",
        welcomeTextColor:"#AC2582",
        inquiryTitleColor:'#001619B2'
      }
    },
    typography:{ 
      "h4":{        
        fontWeight:'bold'
      } ,  
      "h6":{
        fontWeight:'bold'
      },
    },
    shape:{
      button:{
        borderRadius:'20px',
        textTransform:'none'
      },      
      borderRadius:'20px',
      textTransform:'none'
    },
    Stepper:{
      border:{
        main: '#ea625e'
      }
    },
    homePage:{
      navbar:'#ecebfa',
      backgroundColor:'#e3e1f7'
    }
  });
  export default theme;