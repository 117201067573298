import React, { useState, useEffect } from 'react'
import UserNavbarComponent from '../../components/Navbar/UserNavbarComponent'
import UserHomepagePicturecarousel from '../../components/Card/UserHomepagePicturecarousel'
import UserInquiryFormComponent from '../../components/Dialog/Inquiry/UserInquiryFormComponent'
import slide1 from '../../assets/images/Slide.png';
import UserFilterCardComponent from '../../components/Card/UserFilterCardComponent';
import UserCategoryCard from '../../components/Card/UserCatagoryCard';
import CopyrightComponent from './../../components/Copyright/CopyrightComponent';
import FooterComponent from '../../components/Footer/FooterComponent';
import UserpropertyByTownshipCard from './../../components/Card/UserpropertyByTownshipCard';
import { PropertyCatagory, CarCatagory } from '../../data/homePageData';
import { GetPropertyAPI } from '../../api/Listings/property/propertyController';
import { GetCarApi } from '../../api/Listings/car/carController';
import { Box } from '@mui/material';
import UserHomeProductSkeleton from '../../components/Skeleton/UserHomeProductSkeleton';
import UserPopularHotDealCard from '../../components/Card/UserPopularHotDealCard';

const url = "http://nksoftware-001-site17.dtempurl.com/upload/image/caurosel/"


const slides = [
  { src: url + '1.png' },
  { src: url + '2.png' },
  { src: url + '3.png' },
];

function HomePage({ history }) {
  const [openDialog, setOpenDialog] = useState();
  const [PropertyHotDealForSaleData, setPropertyHotDealForSaleData] = useState([])
  const [PeopertypropertyforSaleData, setPeopertypropertyforSaleData] = useState([])
  const [PropertyHotDealforRentData, setPropertyHotDealforRentData] = useState([])
  const [PropertyPopularforRentData, setPropertyPopularforRentData] = useState([])
  const [CarHotDealForsaleData, setCarHotDealForsaleData] = useState([])
  const [CarPopularForsaleData, setCarPopularForsaleData] = useState([])
  const [TotalRecordForProperty, setTotalRecordForProperty] = useState(0)
  const [TotalRecoedForCar, setTotalRecoedForCar] = useState(0)
  const [Data, setData] = useState([])
  const [IsLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const isInquiryPopup = sessionStorage.getItem('isInquiryPopup');

    if (isInquiryPopup !== 'false') {
      setOpenDialog(true);
    }
  }, []);

  useEffect(() => {

    const fetchAllPropertyData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 1,
        }
        await GetPropertyAPI(payload, setData, setTotalRecordForProperty);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    }

    const fetchAllCarData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 1,
        }
        await GetCarApi(payload, setData, setTotalRecoedForCar);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    }

    const fetchPropertyHotDealForSaleData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsHotDeal": "true",
          "Status": "ရောင်းရန်"
        }
        await GetPropertyAPI(payload, setPropertyHotDealForSaleData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const fetchPropertyPopularForSaleData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsPopular": "true",
          "Status": "ရောင်းရန်"
        }
        await GetPropertyAPI(payload, setPeopertypropertyforSaleData,setIsLoading);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const fetchPropertyHotDealForRentData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsHotDeal": "true",
          "Status": "ငှားရန်"
        }
        await GetPropertyAPI(payload, setPropertyHotDealforRentData,setIsLoading);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const fetchPropertyPropularForRentData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsPopular": "true",
          "Status": "ငှားရန်"
        }
        await GetPropertyAPI(payload, setPropertyPopularforRentData,setIsLoading);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const fetchCarHotDealForSaleData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsHotDeal": "true",
          "Status": "ရောင်းရန်"
        }
        await GetCarApi(payload, setCarHotDealForsaleData,setIsLoading);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const fetchCarPopularForSaleData = async () => {
      try {
        const payload = {
          "PageNo": 1,
          "PageSize": 4,
          "IsPopular": "true",
          "Status": "ရောင်းရန်"
        }
        await GetCarApi(payload, setCarPopularForsaleData,setIsLoading);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    fetchAllPropertyData();
    fetchAllCarData();
    fetchPropertyHotDealForSaleData();
    fetchPropertyPopularForSaleData();
    fetchPropertyHotDealForRentData();
    fetchPropertyPropularForRentData();
    fetchCarHotDealForSaleData();
    fetchCarPopularForSaleData();
  }, []);
  const handleClose = () => {
    setOpenDialog(false);
    sessionStorage.setItem('isInquiryPopup', 'false');
  };
  return (
    <>
      <UserNavbarComponent history={history} />
      <UserHomepagePicturecarousel
        slides1={slides}
      />
      <UserInquiryFormComponent
        open={openDialog}
        onClose={handleClose}
      />
      <UserFilterCardComponent
        history={history}
        TotalRecordForProperty={TotalRecordForProperty}
        TotalRecoedForCar={TotalRecoedForCar}
      />

      <Box sx={{
        display: {
          xl: 'flex',  // Flexbox layout for xl screens
        },
        flexDirection: {
          xl: 'column' // Column for xl as well
        },
        justifyContent: {
          xl: 'center' // Center for xl screens
        },
        width: {
          xl: '100%' // 100vw only for xl screens
        },
        height: {
          xl: 'auto'  // Keep same for xl screens
        },
        alignItems: {
          xl: 'center' // Align center for xl screens
        }
      }}>
        <UserCategoryCard
          Title={'အိမ်ခြံမြေအမျိုးအစားများ'}
          PropertyCatagoryData={PropertyCatagory}
          Type={'Property'}
          history={history}
        />

        <UserpropertyByTownshipCard 
        history={history}/>

        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            // hotdeal
            <UserPopularHotDealCard
              Title={'လူကြည့်အများဆုံး အိမ်ခြံမြေအရောင်းကြော်ငြာများ'}
              Data={PropertyHotDealForSaleData}
              Type={'Property'}
              history={history}
              isPopular={false}
            />
        }
        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            // popular
            <UserPopularHotDealCard
              Title={'အိမ်ခြံမြေအရောင်း အထူးကြော်ငြာများ'}
              Data={PeopertypropertyforSaleData}
              Type={'Property'}
              history={history}
              isPopular={true}
            />
        }

        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            // hot deal 
            <UserPopularHotDealCard
              Title={'အိမ်ခြံမြေအငှား အထူးကြော်ငြာများ'}
              Data={PropertyHotDealforRentData}
              Type={'Property'}
              history={history}
              isPopular={false}
            />
        }

        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            // popular 
            <UserPopularHotDealCard
              Title={'လူကြည့်အများဆုံး အိမ်ခြံမြေအငှားကြော်ငြာများ'}
              Data={PropertyPopularforRentData}
              Type={'Property'}
              history={history}
              isPopular={true}
            />
        }
        <UserCategoryCard
          Title={'ကားအမျိုးအစားများ'}
          PropertyCatagoryData={CarCatagory}
          Type={'Car'}
          history={history}
        />

        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            <UserPopularHotDealCard
              Title={'ကားအရောင်း အထူးကြော်ငြာများ'}
              Data={CarHotDealForsaleData}
              Type={'Car'}
              history={history}
              isPopular={false}
            />
        }

        {
          IsLoading ?
            <UserHomeProductSkeleton /> :
            <UserPopularHotDealCard
              Title={'လူကြည့်အများဆုံး ကားအရောင်းကြော်ငြာများ'}
              Data={CarPopularForsaleData}
              Type={'Car'}
              history={history}
              isPopular={true}
            />
        }
      </Box>
      <FooterComponent />


    </>
  )
}

export default HomePage