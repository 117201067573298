import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../theme';
import BedIcon from '../../assets/icons/Bed.png';
import ShowerIcon from '../../assets/icons/Shower.png';
import widthIcon from '../../assets/icons/Width.png';
import LocationIcon from '../../assets/icons/Location.png';
import SharmalLogo from '../../assets/images/SharmalLogo.png';
import CallIcon from '../../assets/icons/InquaryCallIcon.png';
import EmailIcon from '../../assets/icons/EmailIconForProduct.png';
import PopularIcon from '../../assets/icons/PopularIcon.png'
import HotIcon from '../../assets/icons/HotIcon.png';
import StreeringPositionIcon from '../../assets/icons/SteeringPositionIcon.png'
import FuelTypeIcon from '../../assets/icons/FuelTypeIcon.png'
import EnginPowerIcon from '../../assets/icons/EnginPowerIcon.png'
import MileageIcon from '../../assets/icons/MileageIcon.png'
import { CreateInquaryAPI } from '../../api/inquiry/InquiryController';
import SteeringPositionDisplay from './information/SteeringPositionDisplay';

const UserProductDisplayCard = ({ Data, Type, history }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleInquiryForProperty = (property) => {
  

    history.push(`/contact?PropertyId=${property.PropertyId}`)
    window.scrollTo(0, 0);
  };

  
  const safeData = Array.isArray(Data) ? Data : [Data];

  const handleInquiryForCar = (car) => {
  

    history.push(`/contact?CarId=${car.CarId}`)
    window.scrollTo(0, 0);
  }

  const handleDetail = (item) => {
    

    const Property = item.Property ? item.Property.PropertyId : null;
    const Car = item.Car ? item.Car.CarId : null;

    Property !== null ?
      history.push(`/detail?PropertyId=${Property}`) :
      history.push(`/detail?CarId=${Car}`)
    window.scrollTo(0, 0);
  }

  const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'true';
  const resourceEndpoint = isProduction
  ? process.env.REACT_APP_RESOURCE_ENDPOINT
  : process.env.REACT_APP_UAT_RESOURCE_ENDPOINT;
  
  if (safeData.length === 0) return <Typography>No data available</Typography>;
  const phoneNumber = '09752733981';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', marginTop: '10px' }}>
      {safeData.map((item, index) => {
        if (Type === 'property') {
          const {
            Property = {},
            Images = []
          } = item;

          const {
            Code,
            Price,
            Title,
            Location,
            Description,
            Bedrooms,
            Bathrooms,
            Status,
            Area,
            Type,
            IsPopular,
            IsHotDeal,
            CreatedDate
          } = Property;
          
          // Get the first image or use a placeholder if none is available
          const imageUrl = Images[0]?.ImageName
            ? `${resourceEndpoint}${Images[0].CreatedBy}/Property/${Images[0].ImageName}`
            : null;

          return (
            <Box key={index}
              onClick={() => handleDetail(item)}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#ffffff',
                height: 'auto',
                width: isMobile ? '90%' : '80%',
                padding: isMobile ? '2%' : '1%',
                marginBottom: '20px',
                borderRadius: '25px',
                '&:hover': {
                  transform: 'scale(1.01)', // Scale the Box on hover to create a pop effect
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add a shadow to enhance the effect
                }
              }}>
              {/* Picture and Detail */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: isMobile ? '10px' : '20px',
                borderBottom: '1px solid #1D1B201F',
                paddingBottom: '5px',
                justifyContent: 'space-evently',

              }}>

                <img
                  src={imageUrl}
                  alt="Property"
                  style={isMobile ? { width: '50%',aspectRatio: '1/1' } : { width: '50%', height: 'auto', aspectRatio: '1/1' }}
                />

                {/* Price */}
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Box
                    className="Price"
                    sx={{ display: 'flex', justifyContent: 'left', gap: "5px", alignItems: 'flex-end' }}
                  >
                    <Typography variant='p' sx={{ fontSize: isMobile ? '20px' : '40px', fontWeight: '500' }}>
                      {Price}
                    </Typography>
                    <Typography variant='p' sx={isMobile ? { fontSize: '10px' } : {}}>သိန်း</Typography>
                    <Typography variant='p' sx={{ color: '#B1630B', fontSize: isMobile ? '10px' : '12px', marginTop: '10px' }}>
                      (ညှိနှိုင်း)
                    </Typography>
                  </Box>

                  {/* Icon for Show */}
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #C2C2C2',
                    gap: '20px',
                    width: '90%',
                    paddingBottom: '10px'
                  }}>
                    <Box className="bed">
                      <img src={BedIcon} alt="Bed" style={{ width: isMobile ? '15px' : '25px', aspectRatio: '1/1' }} />
                      <Typography variant='p' sx={{ fontSize: '13px', marginLeft: '4px' }}>
                        {Bedrooms || 0}
                      </Typography>
                    </Box>

                    <Box className="shower">
                      <img src={ShowerIcon} alt="Shower" style={{ width: isMobile ? '15px' : '25px', aspectRatio: '1/1' }} />
                      <Typography variant='p' sx={{ fontSize: '13px', marginLeft: '4px' }}>
                        {Bathrooms || 0}
                      </Typography>
                    </Box>

                    <Box className="width">
                      <img src={widthIcon} alt="Width" style={{ width: isMobile ? '15px' : '25px', aspectRatio: '1/1' }} />
                      <Typography variant='p' sx={{ fontSize: '13px', marginLeft: '4px' }}>
                        {Area}
                      </Typography>
                    </Box>
                  </Box>

                  {/* state and type */}
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    gap: '5px'
                  }}>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#EA625E',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#EA625E33",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {Status}
                    </Typography>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#5B1144',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#5B114433",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {Type}
                    </Typography>
                  </Box>

                  {/* Location */}
                  <Box
                    className="Location"
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '5px',
                      marginBottom: isMobile ? '2px' : '5px'
                    }}
                  >
                    <img src={LocationIcon} style={{ width: isMobile ? '6px' : '11px', height: isMobile ? '9px' : '15px' }} />
                    <Typography variant='p' sx={{ fontSize: isMobile ? '6px' : '10px', color: '#001619B2' }}>
                      {Location}
                    </Typography>
                  </Box>

                  {/* Title */}
                  <Typography
                    variant='p'
                    className='UserPageTitleStyle'
                    sx={{
                      marginBottom: isMobile ? '0' : '20px',
                      marginTop: isMobile ? '5px' : '20px',
                      textAlign: 'left',
                      color: '#CC3A35',
                      width: isMobile ? '100%' : '300px',
                      height: isMobile ? 'auto' : 'auto',
                      fontSize: isMobile ? '8px' : '17px',
                    }}
                  >
                    <span className='CodeStyle'>{Code}</span>
                    {Title}
                  </Typography>

                  {/* Description */}
                  <Typography variant='p' sx={{ display: "inline-block", fontSize: isMobile ? '5px' : '15px', color: '#001619B2', width: "100%", height: isMobile ? '50%' : '50%', maxHeight: isMobile ? '30px' : 'none', overflowX: "hidden", overflowY: "hidden", textOverflow: "ellipsis" }}>
                    {Description}
                  </Typography>

                  {/* Created Date */}
                  <Typography variant='p' sx={{ fontSize: isMobile ? '8px' : '10px', color: '#001619B2', marginTop: isMobile ? '10px' : '40px' }}>
                    {/* Created date needs to be formatted if necessary */}
                    {`ကြော်ငြာတင်သည့်နေ့: ${CreatedDate}`} {/* Replace with actual created date if available */}
                  </Typography>
                </Box>


                {IsHotDeal !== false || IsPopular !== false ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '2%',
                      left: isMobile ? ' -2.5%' : '-1%'
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? '80px' : '100px',
                        paddingY: isMobile ? '3px' : '5px',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        backgroundColor: IsPopular === true ? 'rgba(172,37,130,1)' : '#FBB96F',
                        background: IsPopular === true
                          ? 'linear-gradient(10deg, rgba(172,37,130,1) 27%, rgba(70,15,53,1) 100%)'
                          : '#FBB96F',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px 10px 10px 0',
                        gap: '4px'
                      }}
                    >
                      <img
                        src={IsPopular === true ? PopularIcon : HotIcon} // Fixed image source syntax
                        alt=""
                        style={{ width: isMobile ? '12px' : '14px', height: isMobile ? '12px' : '14px' }}
                      />
                      <Typography
                        variant='p'
                        sx={{ fontWeight: 'bold', fontSize: isMobile ? '12px' : '14px', color: '#FFFFFF' }}
                      >
                        {IsPopular === true ? 'Popular' : 'Hot Deal'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: isMobile ? -30 : -38,
                        left: 0,
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                        borderWidth: '0 10px 10px 0',
                        borderColor: IsPopular === true
                          ? 'transparent #5b1144 transparent transparent'
                          : 'transparent #EA625E transparent transparent',
                        transform: 'rotate(0deg)'
                      }}
                    />
                  </Box>
                ) : null}
              </Box>

              {/* Card Footer Function */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingY: '15px'
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '5px'
                }}>
                  <img src={SharmalLogo}
                    alt="Sharmal Logo"
                    style={{
                      width: isMobile ? '25px' : '50px',
                      aspectRatio: '2/1.3',
                      border: '1px solid #1D1B201F',
                      padding: '5px'
                    }}
                  />
                  <Typography variant='p' sx={isMobile ? { fontSize: '10px' } : { fontWeight: '500px' }}>Shar Mal Real Estate Agency</Typography>
                </Box>

                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '5px'
                }}>
                  <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" sx={{
                      width: isMobile ? "100px" : '170px',
                      height: '40px',
                      backgroundColor: '#EA625E',
                      borderRadius: '10px',
                      fontSize: isMobile ? '4.5px' : '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      display: isMobile ? 'block' : 'none'
                    }}>
                      <img
                        src={CallIcon}
                        alt="CallIcon"
                        style={{ width: isMobile ? '15px' : '24px', height: isMobile ? '15px' : '24px', marginRight: '10px' }}
                      />
                      ဖုန်းဖြင့်ဆက်သွယ်ရန်
                    </Button>
                  </a>

                  <Button variant="contained" onClick={() => handleInquiryForProperty(Property)} sx={{
                    width: isMobile ? "100px" : '170px',
                    height: '40px',
                    backgroundColor: '#FBB96F',
                    borderRadius: '10px',
                    fontSize: isMobile ? '5px' : '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: '#001619B2',
                    '&:hover': {
                      backgroundColor: '#F3A850', // Change the background color on hover
                      color: '#FFFFFF', // Change the text color on hover (optional)
                    }
                  }}>
                    <img
                      src={EmailIcon}
                      alt="EmailIcon"
                      style={{ width: isMobile ? '15px' : '24px', height: isMobile ? '15px' : '24px', color: '#001619B2', marginRight: '10px' }}
                    />
                    Inquiry ပေးပို့ရန်
                  </Button>
                </Box>
              </Box>


            </Box>
          );
        } else if (Type === 'car') {
          const {
            Car = {},
            Images = []
          } = item;

          const {
            Code,
            Price,
            Title,
            Location: propertyLocation,
            SteeringPosition,
            FuelType,
            Mileage,
            EnginePower,
            Description,
            Condition,
            Manufacturer,
            CarColor,
            Model,
            PlateDivision,
            Status,
            Type,
            IsPopular,
            IsHotDeal,
            CreatedDate
          } = Car;

          // Get the first image or use a placeholder if none is available
          const imageUrl = Images[0]?.ImageName
            ? `${resourceEndpoint}${Car.CreatedBy}/Car/${Images[0].ImageName}`
            : null;
          
          return (
            <Box key={index}
              onClick={() => handleDetail(item)}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#ffffff',
                height: 'auto',
                width: isMobile ? '90%' : '80%',
                padding: isMobile ? '2%' : '1%',
                marginBottom: '20px',
                borderRadius: '25px',
                '&:hover': {
                  transform: 'scale(1.01)', // Scale the Box on hover to create a pop effect
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add a shadow to enhance the effect
                }
              }}>
              {/* Picture and Detail */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: isMobile ? '10px' : '20px',
                borderBottom: '1px solid #1D1B201F',
                paddingBottom: '5px',
                justifyContent: 'space-evently',
              }}>
                <img
                  src={imageUrl}
                  alt="Car"
                  style={isMobile ? { width: '50%',aspectRatio: '1/1' } : { width: '50%', height: 'auto', aspectRatio: '1/1' }}
                />

                {/* Price */}
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',

                }}>
                  <Box
                    className="Price"
                    sx={{ display: 'flex', justifyContent: 'left', gap: "5px", alignItems: 'flex-end' }}
                  >
                    <Typography variant='p' sx={{ fontSize: isMobile ? '20px' : '40px', fontWeight: '500' }}>
                      {Price}
                    </Typography>
                    <Typography variant='p' sx={isMobile ? { fontSize: '10px' } : {}}>သိန်း</Typography>
                    <Typography variant='p' sx={{ color: '#B1630B', fontSize: isMobile ? '10px' : '12px', marginTop: '10px' }}>
                      (ညှိနှိုင်း)
                    </Typography>
                  </Box>

                  {/* Icon for Show */}
                  {isMobile ? (
                    <Box
                      className="Property"
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: "center",
                        borderBottom: '1px solid #C2C2C2',
                        paddingBottom: '7px',
                      }}
                    >
                      {/* First Row */}
                      <Box
                        sx={{
                          width: '200px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <SteeringPositionDisplay steeringPosition={SteeringPosition} isMobile={isMobile} />
                        <Box className="FuelType" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                          <img src={FuelTypeIcon} alt="" style={{ width: '18px', height: '18px' }} />
                          <Typography variant='p' sx={{ fontSize: '13px', marginLeft: '4px' }}>
                            {FuelType || 'N/A'}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Second Row */}
                      <Box
                        sx={{
                          width: '200px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box className="EnginePower" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                          <img src={EnginPowerIcon} alt="" style={{ width: '18px', height: '18px' }} />
                          <Typography variant='p' sx={{ fontSize: '13px', marginLeft: '4px' }}>
                            {`${EnginePower}` || 'N/A'}
                          </Typography>
                        </Box>
                        <Box className="Mileage" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                          <img src={MileageIcon} alt="" style={{ width: '18px', height: '18px' }} />
                          <Typography variant='p' sx={{ fontSize: '10px', marginLeft: '4px' }}>
                            {`${Mileage}km` || 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>) : (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderBottom: '1px solid #C2C2C2',
                      gap: isMobile ? '10px' : '20px',
                      width: isMobile ? '100%' : '50%',
                      paddingBottom: '10px'
                    }}>
                      <Box className="SteeringPosition" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                        <img src={StreeringPositionIcon} alt="" style={{ width: isMobile ? '15px' : '20px', height: isMobile ? '15px' : '20px' }} />
                        <Typography variant='p' sx={{ fontSize: isMobile ? '10px' : '13px', marginLeft: '4px', width: isMobile ? '50px' : '70px' }}>
                          {SteeringPosition === "LeftHandDrive" ? "ဘယ်မောင်း" : "ညာမောင်း" || 'N/A'}
                        </Typography>
                      </Box>
                      <Box className="FuelType" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                        <img src={FuelTypeIcon} alt="" style={{ width: isMobile ? '15px' : '20px', height: isMobile ? '15px' : '20px' }} />
                        <Typography variant='p' sx={{ fontSize: isMobile ? '10px' : '13px', marginLeft: '4px' }}>
                          {FuelType || 'N/A'}
                        </Typography>
                      </Box>

                      <Box className="EnginePower" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                        <img src={EnginPowerIcon} alt="" style={{ width: isMobile ? '15px' : '20px', height: isMobile ? '15px' : '20px' }} />
                        <Typography variant='p' sx={{ fontSize: isMobile ? '10px' : '13px', marginLeft: '4px' }}>
                          {`${EnginePower}` || 'N/A'}
                        </Typography>
                      </Box>
                      <Box className="Mileage" sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
                        <img src={MileageIcon} alt="" style={{ width: isMobile ? '15px' : '20px', height: isMobile ? '15px' : '20px' }} />
                        <Typography variant='p' sx={{ fontSize: isMobile ? '8px' : '10px', marginLeft: '4px' }}>
                          {`${Mileage}km` || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>)}

                  {/* state and type */}
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    gap: '5px'
                  }}>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#EA625E',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#EA625E33",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {Condition}
                    </Typography>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#5B1144',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#5B114433",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {Manufacturer}
                    </Typography>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#5B1144',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#5B114433",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {Model}
                    </Typography>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#5B1144',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#5B114433",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {PlateDivision}
                    </Typography>
                    <Typography variant='p' sx={{
                      display: 'block',
                      color: '#5B1144',
                      fontSize: isMobile ? '6px' : '8px',
                      backgroundColor: "#5B114433",
                      padding: isMobile ? '3px 6px' : '5px 10px',
                      margin: 'auto 0'
                    }}>
                      {CarColor}
                    </Typography>
                  </Box>

                  {/* Location */}
                  <Box
                    className="Location"
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '5px',
                      marginBottom: '5px'
                    }}
                  >
                    <img src={LocationIcon} style={{ width: isMobile ? '6px' : '11px', height: isMobile ? '9px' : '15px' }}  />
                    <Typography variant='p' sx={{ fontSize: isMobile ? '6px' : '10px', color: '#001619B2' }}>
                      {propertyLocation}
                    </Typography>
                  </Box>

                  {/* Title */}
                  <Typography
                    variant='p'
                    className='UserPageTitleStyle'
                    sx={{
                      marginBottom: isMobile ? '0' : '20px',
                      marginTop: isMobile ? '5px' : '20px',
                      textAlign: 'left',
                      color: '#CC3A35',
                      width: isMobile ? '100%' : '300px',
                      height: isMobile ? 'auto' : 'auto',
                      fontSize: isMobile ? '8px' : '17px',
                    }}
                  >
                    <span className='CodeStyle'>{Code}</span>
                    {Title}
                  </Typography>

                  {/* Description */}
                  <Typography variant='p' sx={{ display: "inline-block", fontSize: isMobile ? '5px' : '15px', color: '#001619B2', width:isMobile? '80%' : "100%", height: isMobile ? '50%' : '50%', maxHeight: isMobile ? '30px' : 'none', overflowX: "hidden", overflowY: "hidden", textOverflow: "ellipsis" }}>
                    {Description}
                  </Typography>

                  {/* Created Date */}
                  <Typography variant='p' sx={{ fontSize: isMobile ? '8px' : '10px', color: '#001619B2', marginTop: isMobile ? '10px' : '40px' }}>
                    {/* Created date needs to be formatted if necessary */}
                    {`ကြော်ငြာတင်သည့်နေ့: ${CreatedDate}`} {/* Replace with actual created date if available */}
                  </Typography>


                </Box>

                {IsHotDeal !== null && IsPopular !== null ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '2%',
                      left: isMobile ? ' -2.5%' : '-1%'
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? '80px' : '100px',
                        paddingY: isMobile ? '3px' : '5px',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        backgroundColor: IsPopular === true ? 'rgba(172,37,130,1)' : '#FBB96F',
                        background: IsPopular === true
                          ? 'linear-gradient(10deg, rgba(172,37,130,1) 27%, rgba(70,15,53,1) 100%)'
                          : '#FBB96F',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px 10px 10px 0',
                        gap: '4px'
                      }}
                    >
                      <img
                        src={IsPopular === true ? PopularIcon : HotIcon} // Fixed image source syntax
                        alt=""
                        style={{ width: isMobile ? '12px' : '14px', height: isMobile ? '12px' : '14px' }}
                      />
                      <Typography
                        variant='p'
                        sx={{ fontWeight: 'bold', fontSize: isMobile ? '12px' : '14px', color: '#FFFFFF' }}
                      >
                        {IsPopular === true ? 'Popular' : 'Hot Deal'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: isMobile ? -30 : -38,
                        left: 0,
                        width: 0,
                        height: 0,
                        borderStyle: 'solid',
                        borderWidth: '0 10px 10px 0',
                        borderColor: IsPopular === true
                          ? 'transparent #5b1144 transparent transparent'
                          : 'transparent #EA625E transparent transparent',
                        transform: 'rotate(0deg)'
                      }}
                    />
                  </Box>
                ) : null}
              </Box>

              {/* Card Footer Function */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingY: '15px'
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '5px'
                }}>
                  <img src={SharmalLogo}
                    alt="Sharmal Logo"
                    style={{
                      width: isMobile ? '25px' : '50px',
                      aspectRatio: '2/1.3',
                      border: '1px solid #1D1B201F',
                      padding: '5px'
                    }}
                  />
                  <Typography variant='p' sx={isMobile ? { fontSize: '10px' } : { fontWeight: '500px' }}>Shar Mal Real Estate Agency</Typography>
                </Box>

                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '5px'
                }}>
                  <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" sx={{
                      width: isMobile ? "100px" : '170px',
                      height: '40px',
                      backgroundColor: '#EA625E',
                      borderRadius: '10px',
                      fontSize: isMobile ? '4.5px' : '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      display: isMobile ? 'block' : 'none'
                    }}>
                      <img
                        src={CallIcon}
                        alt="CallIcon"
                        style={{ width: isMobile ? '15px' : '24px', height: isMobile ? '15px' : '24px', marginRight: '10px' }}
                      />
                      ဖုန်းဖြင့်ဆက်သွယ်ရန်
                    </Button>
                  </a>

                  <Button variant="contained" onClick={() => handleInquiryForCar(Car)} sx={{
                    width: isMobile ? "100px" : '170px',
                    height: '40px',
                    backgroundColor: '#FBB96F',
                    borderRadius: '10px',
                    fontSize: isMobile ? '5px' : '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: '#001619B2',
                    '&:hover': {
                      backgroundColor: '#F3A850', // Change the background color on hover
                      color: '#FFFFFF', // Change the text color on hover (optional)
                    }
                  }}>
                    <img
                      src={EmailIcon}
                      alt="EmailIcon"
                      style={{ width: isMobile ? '15px' : '24px', height: isMobile ? '15px' : '24px', color: '#001619B2', marginRight: '10px' }}
                    />
                    Inquiry ပေးပို့ရန်
                  </Button>
                </Box>
              </Box>


            </Box>

          );
        }
      })}
    </div>
  );
};

export default UserProductDisplayCard;
