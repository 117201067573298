import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography, Box } from "@mui/material";
import notFoundImage from "../assets/images/404.png";
import UserNavbarComponent from "../components/Navbar/UserNavbarComponent";

const NotFoundPage = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/"); // Redirect to the home or any other page
  };

  return (
    <Fragment>
      <UserNavbarComponent />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "20px",
          background: "linear-gradient(180deg, #E0DEF7 0%, rgba(224, 222, 247, 0) 100%)",
        }}
      >
        <Box
          component="img"
          src={notFoundImage}
          alt="404"
          sx={{
            width: "100%",
            maxWidth: "400px", // Adjust width as needed
            marginBottom: "20px",
          }}
        />
      </Box>
    </Fragment>
  );
};

export default NotFoundPage;
